h3#comments {
    margin-top: 48px;
}
.comment-notes {
    display: none;
}
.commentlist {
    margin-top: 24px;
    list-style: none;
    padding: 0;
    a {
        color: $orange;
        &:hover {
            text-decoration: underline;
        }
    }
    li {
        padding: 10px 0;
    }

    .comment-body {
        background: $white;
        padding: 15px 21px;
        .comment-author {
            font-size: 20px;
            font-weight: bold;
        }
        .comment-meta {
            font-size: 14px;
            margin-bottom: 20px;
        }
        p {
            // line-height: 2;
            white-space: normal;
            font-size: 16px;
        }
    }
    .avatar, .says {
        display: none;
    }
    .reply {
        display: inline-block;
        .comment-reply-login {
            display: none;
        }
    }
    ul.children {
        list-style: none;
        padding-right:  0;
        padding-left: 30px;
        margin: 10px 0;
    }

}
.comment-form {
    p {
        label {
            display: block;
        }
    }
}
.comment-respond {
    margin-top: 48px;
    h3 {
        margin-bottom: 12px;
    }
    .comment-form-comment {
        label {
            display: block;
        }
        textarea {
            width: 100%;
        }
    }
    input.submit {
        background: $orange;
        &:hover{
            background: white;
            color: $orange;
        }
    }
}
