.landing-page-nav-container {
    background-color: $white;
    //box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);

    .logo img{
        max-height: 92px;
    }
}
.landing-page-nav {
    height: 92px;
    >div:last-child svg{
        float: right;
        width: 24px;
    }
    svg {
        float: left;
        height: 92px;
        line-height: 92px;
    }
    a {
        
    }
    span {
        float: left; height: 92px; line-height: 92px;
        font-weight: bold;
        font-size: 15px;
        &.light {
            float: right;
            font-size: 14px;
            color: $light-gray;
            margin-left: 11px;
            font-weight: normal;
        }
    }

}

.landing-page-header {
    height:calc(100vh - 92px);
    color: $white;
    background: url('../images/landing-page-header-image.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    .tooltip-container {
        position: static;
        .tooltip-images{
            right: 64px;
        }
    }
    
}

main.landing-page {
    
    background: $dirty-white;
    margin-top: 0;
    padding-bottom: 280px;
    p>a {
        color: $orange;
        &:hover,&:focus {
            text-decoration: underline;
            color: $dark-gray;
        }
    }

    .banner {
        &.banner-desktop {
            margin: 64px 0 24px 0;
        }

        &.banner-mobile {
            margin: 24px 0 64px 0;
        }

        img {
            width: 170px;
        }

        .placeholder {
            background: #ff8a50;
            width: 170px;
            padding: 50px 20px;
            span {
                display: block;
                width: 100%;
                height: 100%;
                font-size: 16px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: center;
                color: $white;
            }
        }
    }
}

.content-nav {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 9;
    height: 64px;
    font-size: 16px;
    width: 100vw;
    
    .container .row {
        >div {
            background: $white;
            box-shadow: 0 -5px 20px 0 rgba(0, 0, 0, 0.05);
            ul {
                list-style: none;
                margin: 0;
                text-align: center;
                li {
                    display: inline-block;
                    height: 64px;
                    line-height: 64px;
                    font-weight: bold;
                    text-transform: uppercase;
                    
                }
            }
        }
    }
}