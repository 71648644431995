footer {
    padding-top: 40px;
    padding-bottom: 70px;
    
    .row:first-child >div {
        //min-height: 230px;
        .socials-menu {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                display: inline-block;
                padding: 32px 12px;
                a {
                    transition: .2s ease-in-out;
                    color: #000;
                    &:hover {
                        color: $main-text;
                    }
                }
                i.fa {
                    font-size: 32px;
                }
            }
        }
    }
    hr {
        margin: 40px 0;
    }
    a {
        color: #5e5e5e;
        transition: .2s ease-in-out color;
        &:hover {
            color: $orange;
            text-decoration: none;
        }
    }
    h5 {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 8px;
        a {
            color: $main-text;
        }
    }
    p {
        color: #5e5e5e;
        font-size: 14px;
        font-weight: normal;
        white-space: normal;
    }
    
    img[alt='stgu'] {
        margin-top: 23px;
    }
    svg {
        height: 15px;
        width: 80px;
    }

    ul.temporary-menu {
        list-style: none;
        padding: 0;
        margin: 0;
        font-weight: normal;
        li {
            font-size: 14px;
            padding: 2px 0;
        }
    }
    .cc-box {
        svg {
            margin-top: 10px;
        }
    }
}